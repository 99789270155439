/**
 * 时间格式化
 * @param {Object} fmt
 */
Date.prototype.format = function(fmt) {
	var showDayArr = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
	var o = {
		'M+': this.getMonth() + 1, //月份
		'd+': this.getDate(), //日
		'E+': showDayArr[this.getDay()], //周
		'D+': this.getDate(), //日
		'H+': this.getHours(), //小时
		'm+': this.getMinutes(), //分
		's+': this.getSeconds(), //秒
		'q+': Math.floor((this.getMonth() + 3) / 3), //季度
		S: this.getMilliseconds() //毫秒
	}
	if (/(y+)/i.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
	}
	for (var k in o) {
		if (new RegExp('(' + k + ')').test(fmt)) {
			fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
		}
	}
	return fmt
}

