import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
      {
            path: '/',
            name: 'home',
            component: () =>
                  import(/* webpackChunkName: "home" */ '@/views/home/home.vue'),
            meta: { name: '首页' },
      },
      {
            path: '/relationPictrue',
            name: 'relationPictrue',
            component: () =>
                import(/* webpackChunkName: "login" */ '@/views/login/login.vue'),
            meta: { name: '关联关系图谱' },
      },
      {
            path: '/login',
            name: 'login',
            component: () =>
                  import(/* webpackChunkName: "login" */ '@/views/login/login.vue'),
            meta: { name: '登录' },
      },
      {
            path: '/conduct',
            name: 'conduct',
            component: () =>
                  import(
        /* webpackChunkName: "conduct" */ '@/views/conduct/conductList.vue'
                  ),
            meta: { name: '产品列表' },
      },
      {
            path: '/conductDetail',
            name: 'conductDetail',
            component: () =>
                  import(
        /* webpackChunkName: "conduct" */ '@/views/conduct/conductDetail.vue'
                  ),
            meta: { name: '产品详情' },
      },
      {
            path: '/lawsuit',
            name: 'lawsuit',
            component: () =>
                  import(
        /* webpackChunkName: "login" */ '@/views/company-detail/lawsuit.vue'
                  ),
            meta: { name: '法律诉讼' },
      },
      {
            path: '/company-detail/lawsuit-cards',
            name: 'lawsuit-cards',
            component: () =>
                  import(
        /* webpackChunkName: "login" */ '@/views/company-cards-components/lawsuit-card.vue'
                  ),
            meta: { name: '法律诉讼card' },
      },
      {
            path: '/company',
            name: 'company',
            component: () =>
                  import(/* webpackChunkName: "conduct" */ '@/views/company/company.vue'),
            meta: { name: '公司列表' },
      },
      {
            path: '/companyBasicInfo',
            name: 'companyBasicInfo',
            component: () =>
                  import(
        /* webpackChunkName: "conduct" */ '@/views/company-detail/companyInfo.vue'
                  ),
            meta: { name: '公司基本信息' },
      },
      {
            path: '/usercenter',
            name: 'userCenter',
            component: () =>
                  import(/* webpackChunkName: "conduct" */ '@/views/user/userCenter.vue'),
            meta: { name: '个人中心' },
      },
      {
            path: '/forgetPwd',
            name: 'forgetPwd',
            component: () =>
                  import(/* webpackChunkName: "conduct" */ '@/views/home/forgetPwd.vue'),
            meta: { name: '忘记密码' },
      },
      {
            path: '/registration',
            name: 'registration',
            component: () =>
                  import(/* webpackChunkName: "conduct" */ '@/views/home/registration.vue'),
            meta: { name: '用户注册' },
      },
    //新增上传客户信息查询和使用授权书
      {
            path: '/customerInfoAndAuth',
            name: 'customerInfoAndAuth',
            component: () => import(/* webpackChunkName: "conduct" */ '@/views/home/customerInfoAndAuth.vue'),
            meta: { name: '客户信息查询和使用授权书上传' },
      },
      {
            path: '/financeDual',
            name: 'financeDual',
            component: () => import(/* webpackChunkName: "conduct" */ '@/views/home/financeDual.vue'),
            meta: { name: '金融机构和新疆征信服务协议' },
      },

      {
            path: '/atlas',
            name: 'atlas',
            component: () =>
                  import(/* webpackChunkName: "conduct" */ '@/views/atlas/atlas.vue'),
            meta: { name: '关联关系图谱' },
      },
      {
            path: '/creditReport',
            name: 'creditReport',
            component: () =>
                  import(/* webpackChunkName: "conduct" */ '@/views/product/creditReport'),
            meta: { name: '企业信用报告' },
      },
      {
            path: '/relevance',
            name: 'relevance',
            component: () =>
                  import(/* webpackChunkName: "conduct" */ '@/views/product/relevance'),
            meta: { name: '中小企业关联图谱' },
      },
      {
            path: '/grade',
            name: 'grade',
            component: () =>
                  import(/* webpackChunkName: "conduct" */ '@/views/product/grade'),
            meta: { name: '信用评分' },
      },
      {
            path: '/check',
            name: 'check',
            component: () =>
                  import(/* webpackChunkName: "conduct" */ '@/views/scheme/check'),
            meta: { name: '风险监测及预警' },
      },
      {
            path: '/solution',
            name: 'solution',
            component: () =>
                  import(/* webpackChunkName: "conduct" */ '@/views/scheme/solution'),
            meta: { name: '科创企业融资解决方案' },
      },
      {
            path: '/retail',
            name: 'retail',
            component: () =>
                  import(/* webpackChunkName: "conduct" */ '@/views/scheme/retail'),
            meta: { name: '零售消费信贷智能决策解决方案' },
      },
      {
            path: '/intellect',
            name: 'intellect',
            component: () =>
                  import(/* webpackChunkName: "conduct" */ '@/views/scheme/intellect'),
            meta: { name: '智能信贷获客系统' },
      },
      {
            path: '/village',
            name: 'village',
            component: () =>
                  import(/* webpackChunkName: "conduct" */ '@/views/information/village'),
            meta: { name: '农村信用信息共享服务平台' },
      },
      {
            path: '/banking',
            name: 'banking',
            component: () =>
                  import(/* webpackChunkName: "conduct" */ '@/views/information/banking'),
            meta: { name: '中小微企业综合金融服务平台' },
      },
      {
            path: '/local',
            name: 'local',
            component: () =>
                  import(/* webpackChunkName: "conduct" */ '@/views/information/local'),
            meta: { name: '地方金融聚信服务平台' },
      },
      {
            path: '/online',
            name: 'online',
            component: () =>
                  import(/* webpackChunkName: "conduct" */ '@/views/information/online'),
            meta: { name: '线上银担直联服务平台' },
      },
      {
            path: '/developer',
            name: 'developer',
            component: () =>
                  import(/* webpackChunkName: "conduct" */ '@/views/developer/developer'),
            meta: { name: '成为开发者' },
      },
      {
            path: '/newproduct/:name',
            name: 'newproduct',
            component: () =>
                  import(/* webpackChunkName: "conduct" */ '@/views/newproduct/index'),
            meta: { name: '新产品' },
      }
]

const router = new VueRouter({
      // mode: 'history',
      // base: process.env.BASE_URL,
      scrollBehavior: () => ({y:0}),
      routes,
})

export default router
