export default {
	loginToken ({commit}, form) {

		// return window.axios.post('/login', form).then(res => {
		// 	console.log(res, 1111)
		// })

		return new Promise((resolve, reject) => {
			setTimeout(() => {
				if (form.name == 'a' && form.pass == 'b') {
					let token = '789'
					commit('setToken', token)
					resolve(token)
				} else {
					reject('error')
				}
			}, 1000)
		})

	}
}
