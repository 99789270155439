"use strict";

import Vue from 'vue';
import axios from 'axios';
import {getHeaderToken, removeHeaderToken, removeUserId} from './auth'
import { Notification, MessageBox, Message } from 'element-ui'
import Nprogress from 'nprogress';
import 'nprogress/nprogress.css';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-wwtuichuw-form-urlencoded';

// let config = {
//   baseURL: window.SITE_CONFIG[`http://192.168.43.136:8090`] || ""
//   // timeout: 60 * 1000, // Timeout
//   // withCredentials: true, // Check cross-site Access-Control
// };
let _axios=""
if(process.env.NODE_ENV==='development'){
  _axios = axios.create({baseURL:`./se`,timeout:300*1000});//本地
}else{
  _axios = axios.create({baseURL:`./cdevservice`,timeout:300*1000});//生产测试
}
// const _axios = axios.create({baseURL:`./cdevservice`,timeout:300*1000});//生产测试
// const _axios = axios.create({baseURL:`./se`,timeout:300*1000});//本地   home页面有个pdf也需要修改
_axios.interceptors.request.use(
  function(config) {
    Nprogress.start()
    // const isToken = (config.headers || {}).isToken === false
    if (getHeaderToken()) {
      config.headers['Authorization'] = 'Bearer ' + getHeaderToken() // 让每个请求携带自定义token 请根据实际情况自行修改
        config.headers['userType'] = 'open'
    }

    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
      if(response.data.code == 401){
          MessageBox.confirm('登录状态已过期，请重新登录', '系统提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
              }
          ).then(() => {
              removeHeaderToken();
              removeUserId();
              location.reload()
          }).catch(() => {});
      }else {
          Nprogress.done()
          // Do something with response data
          return response;
      }

  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
