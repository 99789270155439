import Cookies from 'js-cookie'

const TokenKey = 'Shanxi-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function setHeaderToken(token) {
  return Cookies.set(TokenKey, token)
  // return window.sessionStorage.setItem(TokenKey, token)
}
export function getHeaderToken() {
  return Cookies.get(TokenKey)
  // return window.sessionStorage.getItem(TokenKey)
}
export function removeHeaderToken() {
  return Cookies.remove(TokenKey)
  // return window.sessionStorage.removeItem(TokenKey)
}
export function setUserId(id) {
  return Cookies.set('id', id)
}
export function getUserId() {
  return Cookies.get('id')
}
export function removeUserId() {
  return Cookies.remove('id')
}
